@import "./_*.pcss";

/* Base */

@custom-media --view-xs (width < 600px);

@custom-media --view-sm (600px <= width < 900px);
@custom-media --view-sm-up (width >= 600px);
@custom-media --view-sm-dn (width < 900px);

@custom-media --view-md (900px <= width < 1200px);
@custom-media --view-md-up (width >= 900px);
@custom-media --view-md-dn (width < 1200px);

@custom-media --view-lg (1200px <= width < 1800px);
@custom-media --view-lg-up (width >= 1200px);
@custom-media --view-lg-dn (width < 1800px);

@custom-media --view-xl (width >= 1800px);

:root {
  --black: #180029;
  --white: #fafafa;
  --gap: 1rem;
  --gap-half: 0.5rem;
  --gap-quarter: 0.25rem;

  color: var(--black);
  background: var(--white);
  min-height: 100%;
  display: grid;
}

* {
  margin-top: 0;
}

button,
.btn {
  background: var(--black);
  border: 1px solid var(--white);
  color: var(--white);
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0.25rem;
  transition: 0.25s;

  &:hover {
    background: var(--white);
    border-color: var(--black);
    color: var(--black);
  }
}

input[type="checkbox"].cb-fake {
  display: none;

  & + label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      color: var(--black);
      background: var(--white);
      margin-right: var(--gap-half);
      width: var(--gap);
      height: var(--gap);
      font-size: 1.3rem;
    }
  }

  &:checked + label:before {
    content: '\2713';
  }
}

.strike {
  text-decoration: line-through;
}

/* Layout */

#root {
  display: grid;
  min-height: 100%;
}

.App {
  display: grid;
  grid-gap: var(--gap);
  align-content: center;

  &.App-login {
    justify-items: center;
    grid-auto-flow: row;
  }
}

.options {
  display: grid;
  grid-gap: var(--gap);
  grid-template-areas: "fandoms" "descriptors";
  max-height: 0;
  transition: max-height 0.25s;
  overflow: hidden;

  &.is-open {
    max-height: 100vh;
  }

  &.has-selected-fandom {
    grid-template-areas: "fandoms" "subjects" "descriptors";
  }

  @media (--view-sm) {
    grid-template-areas: "fandoms descriptors";

    &.has-selected-fandom {
      grid-template-areas: "fandoms descriptors" "subjects descriptors";
    }
  }

  @media (--view-md-up) {
    grid-template-areas: "fandoms descriptors";

    &.has-selected-fandom {
      grid-template-areas: "fandoms subjects descriptors";
    }
  }

  & > * {
    background: var(--black);
    color: var(--white);
    padding: var(--gap);
  }

  & .fandoms {
    grid-area: fandoms;
  }

  & .descriptors {
    grid-area: descriptors;
  }

  & .Subjects {
    grid-area: subjects;
  }
}

.options-list {
  list-style-type: none;
  padding: 0;
  outline: none;
}

.action {
  justify-self: center;
}

/* Components */

.HeadCanon {
  justify-self: center;

  & .HeadCanon-item {
    display: inline-block;
  }
}

.form-add {
  margin-bottom: var(--gap);
}

.list-item {
  margin-bottom: var(--gap-quarter);
}

.control-row {
  display: flex;
  align-items: center;

  & .control-stretch {
    flex: 1;
  }
}

[data-tooltip] {
  position: relative;
  text-decoration: underline dashed;
  cursor: help;

  &:after {
    content: attr(data-tooltip);
    display: block;

    font-size: 0.9rem;
    font-weight: 400;
    white-space: nowrap;
    border: 1px solid;
    padding: var(--gap-quarter);

    position: absolute;
    top: 110%;
    left: 25%;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s, visibility 0s 0.25s;
  }

  &:hover:after {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s;
  }
}

.btn-go {
  border: none;
  background: transparent;

  & .btn-go-logo {
    width: 100px;
    max-height: 106px;
  }
}

.btn-login,
.btn-logout {
  border-radius: var(--gap-half);
  padding: var(--gap-half);
}

.btn-options {
  border: none;
  border-bottom: 1px solid var(--black);
  border-radius: 0;
  background: var(--white);
  color: var(--black);
  justify-self: center;
}

.logo-dark-fill,
.logo-lite-fill,
.logo-dark-stroke,
.logo-lite-stroke {
  transition: all 0.5s;
}

.btn-go:hover .logo-dark-fill {
  fill: var(--white);
}

.btn-go:hover .logo-lite-fill {
  fill: var(--black);
}
